<template>
  <div class="mine-detail-container">
    <template v-if="hasChildRouter">
      <div class="mine-detail-wrapper" v-if="orders.length">
        <ul class="item-wrap">
          <li
            class="item-list"
            v-for="(item, i) in orders"
            :key="i"
            @click="targetDetailHandle(item)"
            :style="`background-color: ${getObj(item.status).bgColor};`"
          >
            <ul class="item list-item-wrap">
              <li class="item-line header">
                <div class="icon-wrap">
                  <img :src="getObj(item.status).icon" />
                </div>
                <div
                  class="status"
                  :style="`color: ${getObj(item.status).color}`"
                >
                  {{ getObj(item.status).label }}
                </div>
              </li>
              <li class="item-line">
                <span class="title">活动名称</span>
                <span class="info">{{ item.ticketName }}</span>
              </li>
              <li class="item-line">
                <span class="title">创建时间</span>
                <span class="info">{{ item.createTime }}</span>
              </li>
              <li class="item-line">
                <span class="title">票数</span>
                <span
                  class="info"
                  :style="`color: ${getObj(item.status).color}`"
                  >{{ item.ticketCount }}</span
                >
              </li>
              <!-- <li>
                <div
                  class="btn custom-button pink-button"
                  @click="targetDetailHandle(item)"
                >
                  点击进入详情
                </div>
              </li> -->
            </ul>
          </li>
        </ul>
      </div>
      <div class="empty" v-else>暂无订单</div>
    </template>
    <router-view v-else />
  </div>
</template>

<script>
import { getOrderInfo, buyOrderUrl, getWxUlr } from "@/api/index.js";

export default {
  name: "Mine",
  data() {
    return {
      hasChildRouter: this.$route.params.hasChildRouter,
      token: "",
      orders: [],
      order: {},
      isShow: true,
      qrCodeUrl: "",
      orderStatus: {
        TO_PAY: {
          label: "待支付",
          bgColor: "#fdf5f5",
          color: "#f23c3c",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAqCAMAAAAOCBKjAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABdUExURfeBrfNBRPRMVfQ+P/Zwkvd6ofdrh0dwTPM9PvVccPd/q/iDsPdngPZgdvh4nfiAqvRNWfI0L/qLt/h4m/Zcb/M+QPRGTPI2MvM5N/RNWPdngPVUY/dvjfqErfmApsylLL4AAAARdFJOU2GcdOFiE54AYWE3UrTp2Im+2oabvQAAAAlwSFlzAAAASAAAAEgARslrPgAAAUxJREFUOMuNlIu2giAQRUcFBVF89bTy/z/zAsModGtW27H0uBdmDEIzzF/pZOMBRplni86Lc2aDzmv7Uh4IzsaCzrJsedEeihwOEZxniq0RS4GQ7vFhfa6+wr7i/+HoY1wLISSsKeTIPga11rqFU0rfIT0F3tG5c3oETg86L4LzYMFx7iyFVlrB5Y5bVSaMlBZKKQ2XiG5SqpgWbhgF50iZKrKKqR/ncG5VAoXRubGgc+WY0JncEVbCNYbksPzusD9n+v25zizoXFjQyed5HN/mXfl5T7ulL7Uux//9kzRqCJzVHxk6e7937kz4TeiaWj70vKD1ZUVGF2O/dgQsT7fZQbxjXbxEZ1g2C+4bXIGgch+DdQseAo0xUsJHhjoehNbMLrWNNLn87piwnlvGMftbof3mtOnaMJ+ctsnZLXy7Zrc5CDc0EI8/GXTlDy4CVs4dgMBFAAAAAElFTkSuQmCC"
        },
        PAID: {
          label: "已支付",
          bgColor: "#f0f6fe",
          color: "#5e6fff",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAqCAMAAAAOCBKjAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAzUExURXGT/3KX/3KW/3KY/2uJ/3KZ/2N5/3OZ/2R5/2Z+/19w/36y/2uI/3aj/2R5/2+T/2eA/xjZ06YAAAAKdFJOU2EbRN13qd4BYZwt1eSSAAAACXBIWXMAAABIAAAASABGyWs+AAABDElEQVQ4y92TW5aEIAwFE96MtrL/1Q6BgEGR099d4INYoh834M32ikVPwELZNl2dbYn6woHvnU8ecg7r6nyWTBxtK7o7iHfHN5pkAcDDKUFWUHOBHDU6/VutEIE4l1Rnf+fcyQlLJxNJgZTSTtMZgU1MDBloK+UlbnAOxkgFHVdH53CCXqzOX+MQsxwEOwePXue7UrvtM+W3nWPAxXHNTrqGMyEYxyvhpItSIOtWujJmA+WJTiFeGQsih9pAVerF9hyWzOfYkkFPm5XPRlOc95rnmDsGZhhqoHrrFXicShCtYad0vUQ93inxlQbeK499WsfnX3jZB2VzqNk+Y4vlV9XdUegfoHDU1OAP4j8qLDGycViBXAAAAABJRU5ErkJggg=="
        },
        USED: {
          label: "已使用",
          bgColor: "#e9f9f8",
          color: "#00c4aa",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAqCAMAAAAOCBKjAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABLUExURQHOwhzPvFfi1wHOxDfWxg7LtiHUyUdwTAbHsQrKth/TyC/UxFzk2lHg1AHEq3Xt5y3TwQDGsgvHr0/f02rp4l/l3D3ZyRjMtgDLu2FHwhkAAAAOdFJOU2FynFBg3RMAYZg4smLh5z/efQAAAAlwSFlzAAAASAAAAEgARslrPgAAAR5JREFUOMvV1O1ugyAUgOGDfCNU6zrZ/V/p4AAKhLL+3VtLzPGpJqYKctnetggZgwnZNpfMNs2ieU3aXn+bVzbf0z43X3VuSbkysEL0Jt2PUEGLtUzAGXdxCWsxwqXRuTBmDZx1bk25MgiG2db07dEw2NtO3K70yHS/KMbv8ctJ1YpTH8+jmAKP7Z7KOp6GXqvbeE9qInie6kAuc3heVWbxPMEc0z43j9xRbXetGfd/zTP3wIWvx7OqMzEeRoTXk96km6qU5r35Ka2g4gfVUYY6zorhBJIBbC0GB0loKEezJDybmA4CRumoCO5KCkLAWOlEAP+azSEjBW1xbyg+z2Zi6PVWMO+MqZ8NOjJGtl0qvV2by9zhBSnk/ZEoR34BPH9FmnL+U/sAAAAASUVORK5CYII="
        },
        CANCELED: {
          label: "已取消",
          bgColor: "#f3f3f3",
          color: "#7c8584",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAqCAMAAAAOCBKjAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABXUExURYqKirO0tNfY2JqcnL/BwaqtrZOUlEdwTJCRkc7Pz7e5uYmJibe5uYmJiY+QkJGTk+Li4p+hoairq5eZmczOze3t7bG0s4+QkOfo6NjZ2eHi4sPFxbq9vD6CFKQAAAARdFJOU2EQomJhYJYAYmbhKp5O58DR/BTBcgAAAAlwSFlzAAAASAAAAEgARslrPgAAAURJREFUOMuNlAu3RCAQgCfSA22W9bj4/7/zTg+JpfUZU02fchwAz6b+joZwA2R9gsY5U8rppXNSBGeerk+TnTOneOLMzvmLaTJH48ezJOTsuOfBOdmkTEpJbpxP5FD4HPB77WN05Mk5U0t6cJZTz+SMSkphSVJTapzVHktpdqY2pKxNZcHknNVT8JjSV48OjRUSOQWFYR1MDGsZ4UpIjQo6SeoCgaENhTYKX6kLik47tF+Ekl+nTfF+4LTMOu8kuE7+y2E58sjpklw5Wn85Ap2xC/FiIs/1XkFHIDB2o4nRGhb2Gu3YJLzIOBtaBLxlHbE7WoAXbKuDA5vzYoADcLjWWcz2GRqwI0I2G/oZroAQuIQx37Gv5mFK8Uod5bOj7PdcJRxVba99dedU8behrhzFj4Tbcn/XcCMny06A76uKX2Jm/gHP+FM3U2I/QgAAAABJRU5ErkJggg=="
        },
        INVALID: {
          label: "不可用",
          bgColor: "#fdefe9",
          color: "#fd8180",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAqBAMAAADL+P+iAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAtUExURf1xcEdwTP2AavyKZ/17bP18bP1+a/13bf1+a/1xb/yHaPx5bPyUZPubYfuhX81rQFgAAAAJdFJOU2EAomHbE3c864L8mh0AAAAJcEhZcwAAAEgAAABIAEbJaz4AAAEiSURBVCjPtdExTsMwFIDhJ6rStW8oWaMEULsVRVwAoUqoS3oDQBVjlQxcgJVOiIGoF0CtRxaSmcUKRwhTV3IG3rMTx2nVkX+In7/Yk+GktDtD7MOiRVumsvy1LSYq7H7Ki10qigOUt2to63neKa3fl7OavpBa0uAHIUiZCymZBkua/SACIdZSiHe6OBQ0+8EY1io6mue0ioZMB2hDTR64ex79DkBKxaiaptmGT2XUnZLBc5ZmLoCij2tuyqPb0WTljjV96njiiwm14pIVT0xqp5z/JPUpq3+iqz2awHyHnmiYt+gFuKFFr7eKYGSoFji+0TR6AxMZL043bOzonL+IFcXmV02PiFGbuvwgkU09/UZRQ1rIKupDb1YROpocMMKb8A95X0cFQzD3vAAAAABJRU5ErkJggg=="
        },
        PAY_TIMEOUT: {
          label: "超时未支付",
          bgColor: "#fcf8eb",
          color: "#fb9349",
          icon:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAqBAMAAADL+P+iAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURf+xd0dwTP+wa/+3eP+xa/+qaf6zbP+2ef3AZP+mZv+sc/zJZf6yZP3AZPko8jcAAAAJdFJOU2EAnBZ24bhD4/TQwBYAAAAJcEhZcwAAAEgAAABIAEbJaz4AAAETSURBVCjPxdK/S8NAFMDxpyl09aiQNQQH6ZT+B4raxU3qEFchlE4J/hnS0SEhawvNXyAEJychN7kUvPwvvvcuvR/o4OY3kHt87jJd4PS5dXoU4gRuXWlfibxDbVsg+dIe/U691xfTGz8UrfbUPo7jcxquZkiyV33/KbA1UgQJKKWkku8ok3WnZAQ54NLJbp9l2ZLGCAA6v7/TB7a4puY4MdVYIbi0rkuiErtkmaQ4HujlnkrL2pLT/1D1k3aNW0XUVFy24xombgFzPRi6w+HGoy1QU4e2F0ywMrQZBEYrTdMNmEYPDS1hkFg7PqO3EAMVZov+ROrJ7B1OBXQhuUuBvqPcfjjWFzkY0Xg2kAg1hVbIkm/xjD1LEHOBQwAAAABJRU5ErkJggg=="
        }
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    to.params.hasChildRouter = to.name === "Mine";
    next();
  },
  beforeRouteUpdate(to, from, next) {
    this.hasChildRouter = to.name === "Mine";
    this.hasChildRouter && this.getOrderInfo();
    next();
  },
  created() {
    this.getOrderInfo();
  },
  methods: {
    getObj(key) {
      return this.orderStatus[key] || {};
    },
    getOrderInfo() {
      getOrderInfo().then(({ code, data }) => {
        if (code === 0) {
          if (data.length) {
            this.orders = data.filter(item =>
              ["PAID", "USED", "TO_PAY", "PAY_TIMEOUT"].includes(item.status)
            );
          }
        }
      });
    },
    isWx() {
      //判断是否为微信
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.includes("micromessenger")) {
        return true;
      }
      return false;
    },
    targetDetailHandle(item) {
      if (item.status === "TO_PAY") {
        this.applyWxPay(item.id);

        this.$confirm("请选择支付方式", "支付提示", {
          confirmButtonText: "支付宝支付",
          cancelButtonText: "微信支付",
          distinguishCancelAndClose: true,
          confirmButtonClass: "alipay-btn",
          cancelButtonClass: "el-button--success",
          type: "info",
          center: true,
          callback: type => {
            if (type === "confirm") {
              if (this.isWx()) {
                this.$router.push({
                  name: "Alipay",
                  query: {
                    orderId: item.id
                  }
                });
              } else {
                window.open(buyOrderUrl(item.id));
              }
            } else if (type === "cancel") {
              this.wxPay();
            }
          }
        });
      } else if (item.status === "PAID") {
        this.$router.push({
          name: "MineDetail",
          params: {
            id: item.id
          }
        });
      }
    },
    applyWxPay(orderId) {
      var fp = new window.Fingerprint2();
      fp.get(result => {
        window.$.getJSON(getWxUlr(orderId, result), d => {
          if (d.appId) {
            this.appId = d.appId;
            this.nonceStr = d.nonceStr;
            this.packageValue = d.packageValue;
            this.paySign = d.paySign;
            this.signType = d.signType;
            this.timeStamp = d.timeStamp;
          } else {
            this.$message.warning(d.message);
          }
        });
      });
    },
    onBridgeReady() {
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": this.appId,   //公众号ID，由商户传入
        "timeStamp": this.timeStamp,   //时间戳，自1970年以来的秒数
        "nonceStr": this.nonceStr,      //随机串
        "package": this.packageValue,
        "signType": this.signType,     //微信签名方式：
        "paySign": this.paySign //微信签名
      }, function(res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          window.location.href = "/mine";
        } else {
          alert("支付失败！");
        }
        
      });
    },
    wxPay() {
      if (typeof window.WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.mine-detail-container {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 1px 0 30px;
  background-color: #f8f9ff;
}

.mine-detail-wrapper {
  padding: 0 20px;
  .item-wrap {
    padding-bottom: 20px;
  }
}
.list-item-wrap {
  .item-line.header {
    border-bottom: 1px solid #d0dbee;
    display: flex;
    align-items: center;
    margin-top: -5px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    .icon-wrap {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      background-color: #ffffff;
      margin-right: 5px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
      img {
        width: 20px;
        height: auto;
        margin-top: 8px;
      }
    }
  }
  .item-line {
    .title {
      color: #666666;
      font-weight: normal;
      padding-right: 20px;
    }
    .info {
      color: #333333;
    }
  }
}

.empty {
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background-color: #ffffff;
  padding-top: 50px;
  text-align: center;
}
</style>
